/*===================
 		COMMUN 
 ==================*/
@media only screen and (max-width:1440px) {
	a.navLink{padding: 15px;}
}
@media only screen and (max-width:1340px) {
	.search-top{width:320px;}
	.phare-lib{font-size: 60px;}
	.navigation{display: none;}
	.btn-menu{display: block;}
	li.navItem.nav-promo, li.navItem.nav-nouv, li.navItem.nav-search{transform: none;}
	li.navItem.nav-search span, li.navItem.nav-nouv span, li.navItem.nav-promo span{transform: none;}
	.header-content .logo{width: 80%;}
	.nav-content{padding: 0;}
	.header-menu-mobile{display: block;}
	li.navItem > a.navLink{padding: 15px 20px;font: 16px 'Sofia Pro';text-transform: none;background: url('../images/arrow-menu-mob.png') no-repeat center right 10px;}
	li.navItem > a.navLink.active{background: url('../images/arrow-menu-mob-2.png') no-repeat center right 10px;}
	.navigation.open-mob li.navItem.nav-search a.navLink{font:15px "Sofia Pro-Bold";background-color: transparent;color: #000000;text-transform: uppercase;}
	.navigation.open-mob li.navItem.nav-nouv a.navLink{font:15px "Sofia Pro-Bold";background-color: transparent;color: #6EC43B;text-transform: uppercase;}
	.navigation.open-mob li.navItem.nav-promo a.navLink{font:15px "Sofia Pro-Bold";background-color: transparent;color: #F46036;text-transform: uppercase;}
	.sub-product{background-color: #f5f5f5;}
	.sub-product > li > a.navLink{padding: 15px 10px 15px 30px;border-bottom: 1px dotted #cccccc;}
}
@media only screen and (max-width:1190px) {
	.speed-search{max-width: 50%;padding: 25px 20px;}
	.nice-select ul.list{left: 50%;width: 50%;padding: 20px;}
	.speed-select .nice-select.open{width: 105%;}
	.icon-assurances{padding: 20px 0 0;}
	.icon-assurances .container{max-width:100%;}
	.icon-assurances .container .col-4{padding: 0;}
	.iconeBit{margin-bottom: 25px;}
	.iconeBit a{text-align: center;padding: 45px 0 0!important;background-position: center top;}
	.footer-wrapper{padding: 30px 0;}
	.footer-wrapper .container{max-width: 100%;}
}
@media only screen and (max-width:1023px) {
	.tel-header{margin-right: 15px;}
	.logo img{max-height: 51px;}
	.search-top{display: none;}
	.header-content{padding: 15px 10px;}
	.link-top a{font-size: 1px;color:#0A1533;padding-top: 32px;}
	.link-top a:hover{color:#0A1533;}
	.slider-item{background-position: center;}
	.nouv-section{padding: 30px 0;}
	.nouv-section .container{margin: 0;padding: 0 5px;max-width: 100%;}
	.nouv-lib{font-size: 40px;}
	.section-promotion{padding: 30px 0;}
	.section-promotion .container{margin: 0;padding: 0 5px;max-width: 100%;}
	.section-promotion .container .row{margin:0;}
	.section-promotion .container .col-lg-3{padding-left:5px;padding-right:5px;}
	.content-prefooter{padding: 0 0 20px;}
	.content-prefooter .container{margin:0 auto;padding:0;}
	.banner-facebook{margin: 0 0 25px;}
	.texte-perso-footer{padding: 0 15px;}
	.footer-col-lib{font-size: 16px;}
	.ico-pay{margin: 30px 0;max-width: 100%;}
	.ico-pay-item{text-align: center;}
}
@media only screen and (max-width:860px) {
	.nice-select .option{width: 100%;min-height: 40px;line-height: 40px;}
}
@media only screen and (max-width:768px) {
	.tel-header{display: none;}
}
@media only screen and (min-width:767px) {
	ul.footer-link{display: block!important;}
}
@media only screen and (max-width:767px) {
	.hide-767{display: none;}
	.logo{margin-top: 5px;}
	.link-top{margin-top: 6px;}
	.link-top-col{width: 50%;}
	.slider-container{position:relative;}
	.slider-container .swiper-pagination{display: none;}
	.slider-item{background:none;height:auto;}
	.slider-item img{display: block;}
	.speed-search-container{padding: 0;}
	.speed-search{max-width: 100%;padding: 20px;}
	.speed-search-lib{font-size: 36px;text-align: center;}
	.speed-select{position: relative;}
	.speed-select .nice-select.open{width: 100%;background: url('../images/arrow-select.png') no-repeat right center #FFFFFF;}
	.nice-select ul.list{width: 100%;left:0;top:100%;padding: 0;height: auto;}
	.nice-select li.option{min-height: auto;padding: 15px;line-height: 100%;border-top:1px solid #cccccc;width: 100%;}
	.nice-select li.option[data-value="0"] {display:block;}
	.speed-button input{font-size: 16px;}
	.articleBit{padding: 10px;}
	.articleBitBtn{bottom:10px;right:10px;}
	.nouv-slider .articleBit{margin: 5px 0;}
	strong.promo-price{font-size: 18px;}
	.section-phare{background: #110422 url('../images/bg-phare-2.jpg') no-repeat center top;padding: 180px 0 20px;background-size: 100%;}
	.section-phare .container{max-width:100%;}
	.phare-lib{font-size:40px;text-align:center;line-height:normal;}
	.produitPhare-details{padding-top: 0;}
	.section-category{padding:30px 0;}
	.section-category .row{margin-left:0;margin-right:0;}
	.section-category .container{max-width:100%;padding:0;}
	.section-category-lib{font-size:40px;}
	.section-category-desc{padding:0 10px;}
	.categoryBit{margin: 0 auto 20px;}
	.categoryBit-ima{margin-bottom:5px;}
	h4.categoryBit-lib{margin:0 0 5px;}
	h4.categoryBit-lib a{font-size:16px;line-height:100%;}
	.section-promotion-lib{font-size:40px;}
	.section-promotion .articleBit{margin:0 auto 10px;}
	.footer-wrapper{padding: 0 0 20px;}
	.footer-wrapper .container{padding: 0;}
	.footer-col{border-bottom: 1px solid #3B507A;}
	.footer-col-lib{padding: 15px 10px;margin: 0;text-align: center;background: url('../images/arrow-footer-down.png') no-repeat center right 20px;}
	.footer-col-lib.open{background: url('../images/arrow-footer-up.png') no-repeat center right 20px;}
	ul.footer-link{display: none;}
	ul.footer-link li{text-align: center;}
	.footer-contact{padding: 10px 20px;text-align: center;}
	.mail-footer{margin: 15px auto;max-width: 100px;}
	.ico-pay-item{margin-bottom: 15px;}

}
@media only screen and (max-width:520px) {
	.header-top-container{padding: 0 5px;}
	.header-top-container .row{margin: 0;}
	.header-top-container .row .col-2, .header-top-container .row .col-10 {padding: 0;}
	em.nbre-panier{right: 0;}
	.produitPhare-ima{width:100%;margin-bottom:15px;}
	.produitPhare-details{width:100%;}
	.iconeBit a{font-size: 12px;}
	.copyright{padding: 0 50px;}
}
@media only screen and (max-width:520px) {
	em.nbre-panier{right: -10px;}
}

/**===================================
				CATEGORIE
=====================================*/
@media only screen and (max-width: 991px){
	.categorie-top .container{max-width: 100%;padding:0 5px;}
	.filtre-cat .container{max-width: 100%;padding:0 5px;}
	.liste-articles .container{max-width: 100%;padding:0 5px;}
}
@media only screen and (max-width: 767px){
	h1.categorie-lib{font-size: 40px;}
	.categorie-description{text-align: center;}
	.sub-category-list .row{margin: 0 -5px;}
	.sub-category-list .col-lg-4.col-6{padding:0 5px;}
	.subCategoryBit{margin-bottom: 10px;}
		.subCategoryBit img{width: 55px;}
		.subCategoryBit a{font-size: 14px;padding: 30px 10px 10px 65px;min-height: 70px;}
	.content-fitre-categ .row{margin: 0 -5px;}
		.content-fitre-categ .col.filtre-item{padding:0 5px;}
	.liste-articles .row{margin: 0 -5px;}
		.liste-articles .col-lg-3.col-md-4.col-6{padding:0 5px;}
}
@media only screen and (min-width: 680px) {
	.content-fitre-categ{display: block!important;}
}
@media only screen and (max-width: 680px){
	.filter-lib{text-transform: uppercase;margin: 0 20px;padding: 10px 0;background: url('../images/arrow-down.png') no-repeat right center;}
	.content-fitre-categ{display: none;}
	.content-fitre-categ .col.filtre-item{width:100%;flex-basis:100%;margin-bottom: 10px;}
}

/*===================================
				ARTICLE
=====================================*/
@media only screen and (max-width:1190px) {
	.content-article > .container{max-width: 100%;padding : 0;}
		.content-article ol.breadcrumb.ariane-box{padding: 10px;}
		.retour-article{padding:0 10px;}
	.article-bottom-wrapper .container{max-width: 100%;padding : 0;}
	.description-longue{padding: 30px 10px;}
}
@media only screen and (max-width:1024px) {
	.big-img.has-supp{display: none;}
	.supp-img{width: 100%;}
}
@media only screen and (max-width:960px) {
	.btn-add-to-cart{font-size: 16px;width: 220px;}
}
@media only screen and (max-width:767px) {
	.retour-article{display: none;}
	.mobile-titre{display: block;padding: 10px;}
		h1.titre-article-art{font-size: 28px;}
	.photo-article{padding: 0 10px;}
	.article-details{padding: 0 10px;}
	.article-details h1, .article-details .brand-article,.article-details .ref-article{display: none;}
		.btn-add-to-cart{font-size: 22px;width: 300px;}
	.onglet-article{max-width: 100%;}
	.onglet-article-bit a{font-size: 17px;}
}
@media only screen and (max-width:460px) {
	.quantite-panier{float: none;}
	.button-panier{float: none;margin-top: 15px;}
	.btn-add-to-cart{font-size: 20px;width: 100%;}
	.assurance-artBit{font-size: 11px;}
	.onglet-article-bit a{font-size: 14px;}
	.deja-vu-article .row{margin: 0;}
	.deja-vu-article .col-lg-2.col-md-3{padding:0 5px;}
	.dejavuBit{max-width: 140px;}
}

.visible-480{display:none}
.det-visible {padding: 15px 0;}
 @media only screen and (max-width: 1600px){
 	.aide-article-fixed {left: 0;top: auto;bottom: 0;}
 		.content-absolute {position: relative;left: 0;top: 0;width: 100%;max-width: 300px;margin: auto;}
 		.content-absolute .content {text-align: center;padding: 5px;}
		 .content-absolute .content > strong {background-position: left center;padding: 5px 0 5px 30px;display: inline-block;}
 		.content-absolute 	.content::after{display: none;}
 		.content-absolute 	.content > span{display: none;}
 }
 @media only screen and (max-width: 1250px){
	.table-devis{font-size:13px}
	.titre-article-art{font-size:32px}	
	.bloc-devis-perso>h2{font-size:15px}
 }

 @media only screen and (max-width: 1140px){ 
.bloc-avantage.art-av .sprite-av{display:block; margin:auto}
.bloc-avantage.art-av .av-box span{text-align:center; font-size:11px}
}
 @media only screen and (max-width: 1100px){
	.article-avantage .av-box span {font-size: 14px;margin-top: 5px;} 
}

@media only screen and (min-width: 993px){
.tab-content{display:block!important}	
}

@media only screen and (max-width: 992px){
	.panier-deroulant{display: none!important;} 
	.qte-devis .inputqte{width: 30px}
	.aide-article-fixed {height: 0px}
.content-absolute {width: 100%;max-width: 158px;margin-left: auto;margin-right: 7px;margin-bottom: 0px;top: -38px;}
.content-absolute .content > strong{margin-bottom: 0}
.content-absolute .content {font-size: 0;}
.content-absolute .content::after{display: block;top:15px;}
	a.art_contact {position: absolute;left: 0;width: 100%;height: 100%;top: 0;}
.titre-article-art{font-size:28px}	

.big-img{padding-left:0}
	.big-img .picto-promo strong {font-size: 16px;}
.article-right {padding-left: 0;}
.caract-bit.caract-b {margin: 0;padding: 0;}
.qte-panier .form-control{max-width: 50px;}

.top-nouveaute {padding-bottom:1px;}
.bloc-mode {margin-top: 0;}
.bloc-avantage.art-av  .av-box > a {min-width: 100%;}
.lim-descr {font-size: 12px}
.art-descrp-tab {font-size: 12px}
.imgsupp img {max-width: 60px;}
	.visible-980{display:block}
	.prix-art-panier{font-size:15px}
.article-promo{font-size:28px}
.prix-art-panier .picto-promo strong {font-size: 16px;}
	.tab-description-art {margin-top: 30px;}
	.caract-b a img {max-width: 30px;}
	.visible-980{display:block}
.descr-cmd-expand {padding-left: 0;}
.etatbit-art{font-size:12px}
.bloc-avantage.art-av .av-box span{ font-size:10px}
			.btn-panier {font-size:17px; padding-left:4px; padding-right:4px}

.caract-bit .lab-caract{font-size:13px;width: 70x;}
			
.reduc-descr{font-size:12px}
.lien-panier-art {padding: 0;margin-top: 0;}

.content-complementaire .swiper-pagination{position:relative;bottom:0!important; padding:13px 0}
.caract-art-content table td{font-size:13px}

	.article-bottom{border-top:none; padding-bottom:20px}
	.art-qte{width: 180px}
	.art-qte label{font-size:12px;width: 64px;}
	.other-art-carat div > strong{font-size:13px}
	.fb-partage {margin: 15px 0;text-align: center;}
	.art-retour{ right:0; font-size: 11px}
	.niceScroll {max-height: 100%;    min-height: auto;}
	.vu-bloc{width:100%;padding-left:0}

	.article-avantage .av-box{padding-top: 15px} 
	.article-avantage .sprite-av {height: 37px!important;background-size: auto 32px;}
	.article-avantage .av-box span {font-size: 13px;} 			
.article-top{padding-bottom: 30px}
.table-devis {padding-left: 10px;padding-right: 10px;padding-bottom: 3px;}
strong.prix-devis{font-size: 14px}

}
@media only screen and (max-width: 880px){
	.specific-product {margin-top: 20px;}
	.article-right .descr-art {display: none;}
	.lim-descr {font-size: 11px}
.art-descrp-tab {font-size: 11px; margin-top: 10px ;margin-bottom:20px}
.article-promo {font-size: 25px;}
.prix-art-panier{font-size: 16px;    padding: 10px 0;}
.article-left{width:100%;padding:0;}
	.autre-details-art {padding: 10px;margin-top: 0;}
.article-right{width:100%; padding-top:0;max-width: 100%; padding-right: 0}	
	.article-right .hidden-767 ,.article-right  .titre-article-art {display: none;}
		.visible-767.det-visible {display: block;    padding-top: 0;}

	.content-image-supp .slick-prev,.content-image-supp .slick-next {display: none!important;}
	.art-img-content{max-height: 100%;}
	.img-bit-art.other-b{display: none;}
	.other-art-carat div > strong{font-size:13px}	
	.btn-panier-content {text-align: center;}
.art-retour{font: 11px "Gotham-Medium";color:#333333}
	.visible-767{display:block}
	.hidden-767{display:none!important}
	.alert-btn {width: 100%;text-align:center}
	

.content-image-supp .swiper-pagination{position:relative;bottom:0!important; padding:13px 0}	

.hidden-lib767{display:none}
.big-img{float:none; width:100%}	
.content-image-supp{margin-top:0; padding:0; float:none; width:100%; display:none}
	 .little-imag-art.swiper-wrapper {margin: 15px -10px;width: auto!important;}
 .little-imag-art.swiper-wrapper::after {display: block;content: "";clear: both;}
 .img-bit-art {margin-bottom: 0;margin-right: 0!important;float: left;width: 25%!important;padding: 0 10px;}
 	.imgsupp img , .big-img-ar.img-bit-art img{max-width: 90px;}
.other-art-carat div > strong, .plusque {font-size: 12px;}
.choix-retr .input-abs + span{font-size: 12px!important;background-size: 20px;}
.inline-btn + br{display:none}

.av-artbit.av-art1 {width: 43%;} 
.tab-mobile{font-size: 12px;padding: 15px 10px}

	.tab-mobile::after{ font-size:14px}
.content-toggle-tab{font-size:13px}
.caract-art-content table td,.caract-art-content table tr  {display: block;width: 100%;}
.content-toggle-tab.ref-art-content table, .content-toggle-tab.ref-art-content tr th{font-size:14px}
.content-toggle-tab.ref-art-content{overflow-x:auto}
.content-complementaire{padding-bottom:0}
.article-bottom{padding-bottom:10px}
.expand-me .details {width: 100%;}
	
.supp-next { display:none}
 .supp-prev { display:none}
.art-img-content.swiper-container.swiper-container-horizontal {padding-top: 5px;}
.avantage-article{padding:10px 0;margin: 0 -10px;}	 

.titre2-art-f {font-size: 12px;}
.bloc-art-comp {margin-top: 10px;}
.page-article .ariane-box {padding-bottom: 10px;padding-top: 10px;padding-right: 50px;}
.det-visible{padding-bottom:2px}
.ariane-box,.ariane-box *{font-size:11px!important}

.table-btn {width: auto;margin: auto;}
.article-top {padding-bottom: 10px;}
.article-left .content-livraison-index.pos-relative.livraison-product {display: none;}
.article-right .content-livraison-index.pos-relative.livraison-product {display: block!important;height:160px; margin-top: 0;}
.devis-perso.caract-spe-bit.pb-4:last-child {padding-bottom: 0!important;}
.value-select-caract select{max-width: 100%;}
}
@media only screen and (min-width: 601px){
.content-image-supp .swiper-pagination{display:none!important}	
}

@media only screen and (max-width: 600px){
	.content > strong {font-size: 12px;line-height: 14px;background-size: auto 20px;padding-left: 19px;}
	.content-absolute{max-width:125px }
	.page-article a.devis-btn{text-align: left;}
	.content::after {display: block;top: 12px;border-width: 0 10px 10px 0;}

	.caract-bit.caract-b a{margin-left:2px; margin-right:2px}
	.big-img .picto-promo strong {font-size: 13px;}
	.tab-mobile{display:block;position:relative; z-index:1; cursor:pointer}
	.supp-next, .supp-prev {display: none; }
	.supp-img {margin: 0;}
.art-bg {background: #fff;}
.caract-bit select {background-color: #fff;}
.inputqte {background-color: #fff;}

.titre-article-art{font-size:25px}	
.img-bit-art { width: 100%!important;float:none; margin-right:20px!important}
	.img-bit-article img{max-width: 100%}

	.imgsupp img , .big-img-ar.img-bit-art img{max-width: 100%;}
	.img-bit-art a img{width:100%}
a.btn-panier.btn-ctc-panier.show-avertisment{font-size:16px}
.little-imag-art{align-items:center; -webkit-box-align: center;-ms-flex-align: center; }
.article-complementaire  {padding-top: 0;}

.pi-col-5{width:50%}
.column-two{column-count: 1;-webkit-column-count: 1;-ms-column-count: 1;column-gap:0;-webkit-column-gap: 0;-ms-column-gap: 0;}

			.ongle-caractr {display: none;}
			.content-tab-art .pi-tabs-content > .pi-tab-pane{display:block}
			.content-tab-art{border-top: 1px solid rgba(179,179,179,0.5);margin-top:0}
			.content-toggle-tab{border-bottom: 1px solid rgba(179,179,179,0.5)}
			.little-imag-art.swiper-wrapper {margin: 0;}
			.img-bit-art.swiper-slide.imgsupp:nth-child(n+5) {display: block;}
	.articleVuBit-lib {font-size: 11px;}
	.articleVuBit{padding-left:5px; padding-right:5px}

	div.light-rounded .pp-close{    background-size: 25px!important;}
	
  a.pp-previous ,a.pp-next {  background-size: auto 39px!important;}
  .inline-btn + br{display:block}
  .tab-titre{font-size:12px; padding:10px}
  .articleVu-list img {max-width: 80px;}
  .code-ref {font-size: 11px;margin-top:0}
  .big-img.has-supp{display: none;}
  .btn-panier{max-width: 100%; height: 55px}
  .content-btn-panier.out-of-stock .btn-panier{line-height: 55px;}
.bloc-devis-perso{margin-top: 0}
.article-bottom{padding-right: 0;padding-left: 0;}
.article-bottom .swiper-container{margin-top: 10px}
.tab-content{padding: 10px}
.titre-tab{font-size: 18px}
.table-devis .qte-panier {width: 55px;}
}

@media only screen and (max-width: 576px){
	.btn-panier-content.pr-0{padding:0!important;}
}
@media only screen and (max-width: 480px){
	.table-devis{/*overflow-x: scroll;  */  margin-right: 10px;padding-left: 0;padding-right: 0;margin-left: 10px;}
	.table-devis table.text-center {/*width: 630px;*/margin-bottom: 5px;}
	.table-devis::-webkit-scrollbar {width:5px; background:rgba(0,0,0,0.2);-webkit-border-radius:5px;border-radius:5px;-ms-border-radius:5px;-o-border-radius:5px;height:10px;}
	.table-devis::-webkit-scrollbar-thumb{background: rgba(0,0,0,0.2);-webkit-border-radius:5px;border-radius:5px;-ms-border-radius:5px;-o-border-radius:5px;}
	.table-devis::-webkit-scrollbar-track {background:#d6d6d6;border: 1px solid rgba(0,0,0,0.2);-webkit-border-radius:5px;border-radius:5px;-ms-border-radius:5px;-o-border-radius:5px;} 
	.bloc-devis-perso {padding-bottom: 8px;}
.choix-retr .input-abs + span{font-size: 11px!important;}

.caract-bit select{font-size:13px; border:none}	
span.value-selected{border:none}	
	.caract-bit.caract-sel {width: 100%;padding:0  0 0 10px;border: 1px solid #c6c6c6;margin-bottom:5px; display:table} 
	.caract-bit .lab-caract{min-width:auto}
		.caract-bit.caract-sel >* {display: table-cell;}
		.caract-value {min-width: 200px}
.content-toggle-tab{font-size:11px}
.descr-art{display:none}
.prix-art-panier{font-size:15px}
.top-art {width: 100%; }
.px-article{width: 100%;margin-bottom:0;margin-top:0 }

	.sofinco {float: left;width: 50%;}
	.visible-480{display:block}
	.hidden-480{display:none!important}
	.top-art{margin-top:0}
.content-btn-panier .art-qte {max-width: 100%;display: table;}
	.content-btn-panier .quantite-art{width:100%}
		.btn-panier-content{padding-left: 15px!important; margin-top: 10px}
		.content-btn-panier .art-qte >* {display: table-cell;}
.titre-tab{font-size: 16px}
.titre-article-art{font-size:22px}	
}

 /*=====================================
				 PANIER
 =======================================*/
 .lib-mobile{display:none; font-size:12px;  vertical-align:middle}
 .visible-680{display:none}
 
 @media only screen and (max-width:600px) {
	 .table-perso .card-header th, .table-perso .card-header {font-size: 15px;padding-left: 5px; padding-right: 5px;}
	 .table-perso tbody td{font-size: 14px;padding-left: 5px; padding-right: 5px;}
	 .table-perso .sylius-total{font-size: 15px;}
	 .sylius-product-name{font-size: 15px;}
	 .table-perso .sylius-quantity{max-width: 60px;}
	 .titre-h2{font-size: 20px;}
	 .btn-perso-c{font-size: 15px;}
	  .btn-dark-perso{font-size: 15px;}
	 .total-label{font-size: 14px;}
	 .btn-paie{font-size: 22px;}
	 .cart-container.article-top {padding-left: 5px; padding-right: 5px;}
 }
 
 @media only screen and (max-width:576px) {
	 .cart-container.article-top {padding-left: 0; padding-right: 0;}
	 .table-perso span.sylius-quantity input.form-control{font-size: 13px;}
	 .titre-h2{font-size: 18px;}
	 .input-perso-content input.form-control{font-size: 13px;}
	 .cart-container .row.mb-5{margin-left: -5px; margin-right: -5px;}
	 .cart-container .row.mb-5>div{padding-left: 5px; padding-right: 5px;}
	 #sylius-cart-grand-total, .total-cart-summ{font-size: 19px;}
 }
 
 @media only screen and (max-width:400px) {
	 .table-perso .card-header th, .table-perso .card-header{font-size: 13px}
	 .table-perso .sylius-total{font-size: 13px;}
	 .sylius-product-name{font-size: 14px;}
	 .btn-perso-c{font-size: 14px;}
	 .btn-dark-perso{font-size: 14px;}
	 .total-label{font-size: 14px;}
	 #sylius-cart-grand-total, .total-cart-summ{font-size: 18px;}
	 .btn-paie{font-size: 20px;}
 }
 /***********checkout************/
 @media only screen and (max-width:820px) {
	 .content-adress .steps-item{padding-left: 5px; padding-right: 5px;line-height: 50px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 18px;}
	 .content-adress .steps .steps-icon{font-size: 18px;}
	 .form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 13px;}
 }
 
 @media only screen and (max-width:640px) {
	 .content-adress .steps-item{line-height: 40px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 16px;}
	 .content-adress .steps .steps-icon{font-size: 16px;padding-right: 10px;}
	 .content-adress .svg-inline--fa{vertical-align: middle;}
	 .card-body.form-perso-content {padding-left: 10px;padding-right: 10px;}
 }
 @media only screen and (max-width:480px) {
	 .content-adress .steps-item{line-height: 38px;}
	 .content-adress .steps .steps-item .steps-title{font-size: 15px;}
	 .content-adress .steps .steps-icon{font-size: 15px;}
	 .form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 12px;}  
 }
 @media only screen and (max-width:400px) {
	 .content-adress .steps .steps-item .steps-title{font-size: 13px;}
	 .content-adress .steps .steps-icon{font-size: 13px;padding-right: 5px;}
 }
 
 /*=================================
		 CLIENT
 ===================================*/
 @media only screen and (max-width: 1200px){
	 .container.my-0.page-account-content {max-width: 100%;}
 }
 
 @media only screen and (max-width: 1140px){
	 .nom-user strong{font-size:19px}
 /******ADRESSE******/
	 .list-adress .btn-outline-secondary {font-size: 12px;}
 }
 
 @media only screen and (max-width: 1080px){
	 .table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size: 12px;padding-left: 2px;padding-right: 2px;}
	 .table-histo-account.table-perso .action-table-perso .btn-link{font-size:14px;padding-left: 5px;padding-right: 5px}
	 #order-invoices table td, #order-invoices table th {font-size: 12px;padding-left: 5px;padding-right: 5px;}
	 .p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 0;}
 }
 @media only screen and (max-width: 991px){
	 .menu-left .list-group-item.bg-light.rounded-0.border-0 {padding-left: 5px;padding-right: 5px;}
	 .nom-client-p.py-3.px-2 {padding-left: 0!important;padding-right: 0!important;}
	 .list-item-bit a{padding-left: 45px; padding-right: 10px;font-size: 13px;background-position-x: 13px ;}
	 .list-item-bit a::after{left: 45px}
	 .txt-bienvenu{font-size:1.2rem}
	 .navbar-nav + .d-md-none {display: none;}
	 .titre-compte-client .titre-top-content{padding-left: 41px;}
	 .titre-compte-client p.mb-4 {margin-bottom:15px!important}
	 /******ADRESSE******/
	 .list-adress .card.rounded-0.border-0 {border-right: none!important;border-bottom: 1px solid #ccc!important;}
	 .col-12.col-md-4.left-dash {  padding-right: 0;}
	 .image-bienvenu img{max-width: 150%;}
	 #order-invoices table a i.icon.download {	background-size: 15px;width: 15px;height: 15px;}
	 .p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 15px;}
 }
 
 @media only screen and (max-width: 767px){
	 .page-account-content .row.overflow-hidden {position: relative;}
	 .col-12.col-md-4.sylius_shop_account_dashboard {padding-top: 200px;overflow: hidden;padding-right: 15px;}
	 .col-12.col-md-4.sylius_shop_account_dashboard + .col-md-8 {position: absolute;top: 0;left: 0;height: 200px;overflow: hidden;padding:0;}
	 .image-bienvenu h1{display: none;}
	 .image-bienvenu img{top:-19%}
	 .nom-client-p {padding: 0!important;margin-bottom: 10px;margin-top: -55px;z-index: 9;position: relative;text-align: center;}
	 .nom-user {display: block;width: 100%;margin-top: 10px;padding: 0 10px 0 10px;}
 
	 .menu-left {display: none;overflow:visible!important;}
	 .sylius_shop_account_dashboard .menu-left{display: block;}
	 .menu-client >.row>.col-12{padding-left: 8px;padding-right: 8px;}

	 /******ADRESSE******/
	 .list-adress button.btn.btn-link.btn-valider.rounded-0.border-0.text-uppercase.text-white {font-size: 15px;padding: 15px;}
	 .list-adress .card-body{padding-left: 10px; padding-right: 10px;}
	 .titre-compte-client .titre-top-content > strong,.content-bottom-order #order-invoices h3#shipping-state{font-size: 18px;}
 
	 .image-bienvenu img {max-width: 150%;height: auto!important;}
	 .page-sylius_shop_account_dashboard{position: relative;} 
	 .nom-user strong {font-size: 23px;}
 }
 
 @media only screen and (max-width: 600px){
	 .content-bottom-order .table-perso .card-header th {padding-left: 5px;padding-right: 5px;}
	 .content-bottom-order .table-perso .sylius-product-name {font-size: 15px;}
 }
 
 @media only screen and (max-width: 575px){
	 .table-histo-account .sylius-table-column-shippingAddress {display: none!important;}
	 .page-sylius_shop_account_order_show th.sylius-table-column-subtotal.text-right.border-bottom-0 {width: 95px;}
 }
 
 @media only screen and (max-width: 480px){ 
	 .table-histo-account	.action-table-perso>a {display: block;margin-bottom: 1px;}
	 .titre-compte-client .titre-top-content > strong,.content-bottom-order #order-invoices h3#shipping-state{font-size: 16px;} 
	 .content-bottom-order .table-perso .card-header th{font-size: 13px;}
	 .page-sylius_shop_account_order_show th.sylius-table-column-price.text-center.border-bottom-0 {display: none;}
	 .page-sylius_shop_account_order_show table#sylius-order td:nth-child(2){display: none;}
	 .content-bottom-order .table-perso .sylius-product-name {font-size: 13px;}
	 .content-bottom-order .table-perso .card-header,.content-bottom-order #order-invoices table th{font-size: 13px;}
 }
 
 @media only screen and (max-width: 380px){
	 .table-histo-account  .sylius-table-column-state {display: none;}
	 .ariane-box .breadcrumb-item+.breadcrumb-item:before{padding-right: 2px;}
	 .ariane-box .breadcrumb-item+.breadcrumb-item{padding-left: 2px;}
 }
 