.clear{float:none;clear:both;width:0;height:0;}
ul{margin:0;padding:0;}
ul li{list-style:none;margin:0;padding:0;}
a{border:none;outline:none;text-decoration:none;color:inherit;}
a:focus, a:hover{text-decoration:none!important;outline:none;color:#000000;}
button:focus, select:focus, input[type="text"]:focus,input:focus {outline:none;}
.btn-link{border: none;background-color: #000000;color:#FFFFFF!important;padding: 15px 20px;text-transform: uppercase;font:12px "Sofia Pro-Bold";text-align: center;
	border-radius: 5px;-o-border-radius: 5px;-webkit-border-radius: 5px;-moz-border-radius: 5px;}
.btn-link:hover, .btn-link:focus{background-color: #22CAE0;text-decoration: none!important;}
img{border:none;max-width:100%;}
strong, b{font-family:"Sofia Pro-Bold";font-weight: normal;}
body.body{font-family:"Sofia Pro-Light", Arial;color:#000000;font-size:13px;overflow-x: hidden;}
body.no-scrolling{overflow:hidden;}
.big-wrapper{/*background:#ffffff;overflow-x: hidden;*/}
select{-webkit-appearance:none; -moz-appearance:none;appearance:none;-ms-appearance:none;-o-appearance:none;cursor:pointer}
/**remove select apparence IE****/
select::-ms-expand {display: none;}
a > img:hover{
	filter:brightness(120%);
	-moz-filter:brightness(120%);
	-webkit-filter:brightness(120%);
	-ms-filter:brightness(120%);
	-o-filter:brightness(120%);
}
.opacity-hover:hover{
	filter:brightness(120%);
	-moz-filter:brightness(120%);
	-webkit-filter:brightness(120%);
	-ms-filter:brightness(120%);
	-o-filter:brightness(120%);
}

/********input design********/
.input-abs {position: absolute;z-index: 1;width: 100%;left: 0;top: 0;height: 100%;cursor: pointer;opacity: 0;}
.input-rel {position:relative; z-index:1; margin:0 5px 10px 0}
@media (min-width: 1320px){
	.container, .container-lg, .container-md, .container-sm, .container-xl {max-width: 1200px;}
}

.stat, .stat img, .opti, .opti img {position: absolute;left: 0;height: 0;top: -9000px;}
.justify-center .swiper-wrapper {-webkit-box-pack: center!important;-ms-flex-pack: center!important;justify-content: center!important;}
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */color: #000;opacity:1;}
input::-moz-placeholder { /* Firefox 19+ */color: #000;opacity:1;}
input:-ms-input-placeholder { /* IE 10+ */color:  #000;opacity:1;}
input:-moz-placeholder { /* Firefox 18- */color:  #000;opacity:1;}
/*=====================================
 				COMMUN
 =======================================*/
div.container{max-width: 1200px;}
.page-index{}
.big-wrapper{}
.big-wrapper.padding-body{padding-top:150px;}
.header-body{position: relative;z-index:9;}
.header-body.headerFixed{position:fixed;left:0;top:0;width: 100%;z-index: 999;}
.header-body.headerFixed .header-content{padding: 10px;}
.header-top{background-color: #040C1E;color:#FFFFFF;padding:5px 0 0;}
.header-top-container{max-width: 1800px;margin: 0 auto;padding: 0 15px;}
.phrase-admin{color:#FFFFFF;font-size:13px;font-family: 'Sofia Pro';}
.phrase-admin strong, .phrase-admin b{font-family: 'Sofia Pro-Bold';font-weight: normal;}
.phrase-admin span{color: #6EC43B;}
.phrase-admin p{margin: 0;}
.tel-header{float: right;margin-right: 25px;}
.tel-header a{font-family: 'Sofia Pro';color:#FFFFFF;font-size: 13px;display: block;padding: 0 0 0 18px;background: url('../images/ico-phone.png') no-repeat left center;}
.tel-header a:hover, .espace-pro a:focus{color:#22CAE0;font-size: 13px;background: url('../images/ico-phone-hover.png') no-repeat left center;}
.lang-box{float: right;position: relative;background: url('../images/arrow-down.png') no-repeat right center;width: 45px;}
.lang-box img{display: block;}
ul.langue-list{position: absolute;top:22px;left: 0;background-color: #ffffff;padding: 10px 5px 5px;display: none;z-index: 9;}
.header-wrapper{background-color: #0A1533;}
.header-content{max-width: 1800px;width: 100%;margin: auto;padding:30px 10px;}
.btn-menu{display: none;float: left;width: 20%;padding-right:5px;}
.header-content .logo{float: left;width:100%;}
.logo h1{position:fixed;left:-9000px;top:-9000px;font-size: 1px;}
.search-top{position: relative;width: 500px;float: left;}
input.s-text{height: 50px;background-color: #FFFFFF;padding: 0 15px;width: 100%;border: none;font:15px 'Sofia Pro';color:#4D4D4D;border-radius: 0;}
button.s-submit{padding:0;color:#FFFFFF;border: none;width:40px;height: 40px;background-color: #823DD0;font:15px 'Sofia Pro';position: absolute;top:5px;right: 5px;
	border-radius: 5px;-o-border-radius: 5px;-webkit-border-radius: 5px;-moz-border-radius: 5px;}
button.s-submit:hover, button.s-submit:focus{color:#ffffff;background-color: #22CAE0;}
/* Autocomplete */
.search-top .scrollPerso{background-color: #ffffff;position: absolute;width: 100%;left:0;top:100%;z-index: 9;}
.searchInstantBit{padding: 10px;}
	.searchInstantBit a{display: block;color: #000000;border-top: 1px dotted #cccccc;padding: 2px 0;}
	.searchInstantBit a:hover, .searchInstantBit a:focus{color: #22CAE0;}
		.searchInstantBit a img{float: left;width: 50px;margin: 0 10px 0 0;}
		.searchInstantBit a span{font:14px 'Sofia Pro';}
		.searchInstantBit a strong{font:20px 'Sofia Pro';}
.link-top{max-width: 330px;float: right;width: 100%;}
.link-top-col{float: left;width: 25%;}
.link-top a{display: block;color:#ffffff;text-align: center;text-transform: uppercase;font:12px 'Sofia Pro';}
.link-top a:hover{color:#22CAE0;}
a.link-pro{background: url('../images/ico-pro.png') no-repeat top center;padding-top: 40px;}
a.link-magasin{background: url('../images/ico-magasin.png') no-repeat top center;padding-top: 40px;}
a.link-account{background: url('../images/ico-user.png') no-repeat top center;padding-top: 40px;}
a.link-cart{background: url('../images/ico-cart.png') no-repeat top center;padding-top: 40px;position: relative;}
em.nbre-panier{position: absolute;right: 20px;top:0;background-color: #6EC43B;width: 20px;height:20px;text-align: center;color: #FFFFFF;font-size: 12px;padding-top: 2px;
	border-radius: 100%;-o-border-radius: 100%;-webkit-border-radius: 100%;-moz-border-radius: 100%;}
.navigation{width: 100%;background-color: #345AC6;}
.nav-content{padding:0 10px;}
ul.onglets{margin:0;}
li.navItem{float: left;}
li.navItem.nav-promo{float: right;transform: skew(-20deg);}
li.navItem.nav-nouv{float: right;transform: skew(-20deg);}
li.navItem.nav-search{float: right;transform: skew(-20deg);}
li.navItem.nav-search span, li.navItem.nav-nouv span, li.navItem.nav-promo span{transform: skew(20deg);display: block;}
li.navItem.nav-promo a.navLink{background-color: #F46036;}
li.navItem.nav-nouv a.navLink{background-color: #6EC43B;}
li.navItem.nav-search a.navLink{background-color: #000000;}
a.navLink{display: block;padding: 15px 20px;text-transform: uppercase;font:12px "Sofia Pro-Bold";color:#FFFFFF;position: relative;}
li.navItem.nav-nouv a.navLink:hover, li.navItem.nav-search a.navLink:hover, li.navItem.nav-promo a.navLink:hover{color: #ffffff;background-color: #22CAE0;}
li.navItem a.navLink:hover{color: #22CAE0;}
/* Menu mobile */
.navigation .mCSB_inside > .mCSB_container{margin-right: 10px;}
.menu-mob-overlay{position: fixed;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.7);top:0;left:0;z-index: 999;display: none;}
.navigation.open-mob{position: fixed;left: 0; top:0;background-color: #ffffff;max-width: 450px;width: 100%;display: block;z-index: 1000;height: 100%;transform: translateX(0);}
.header-menu-mobile{padding: 15px 10px;background-color: #ffffff;position: relative;display: none;text-align: right;}
.span-menu-mob{text-transform: uppercase;font:14px "Sofia Pro-Bold";color:#FFFFFF;}
.close-menu-mob{}
.navigation.open-mob li.navItem{float: none;width: 100%;border-top:1px solid #DFDFDF;}
.navigation.open-mob li.navItem a.navLink{color: #0A1533;}

.slider-wrapper{background-color: #F5F5F5;position: relative;z-index: 8;}
.speed-search-container{max-width: 1200px;width: 100%;margin:auto;position: relative;padding: 45px 0;z-index: 9;min-height: 700px;}
.speed-search-content{position: relative;}
.speed-search{width: 100%;max-width: 510px;background-color: rgba(32,53,158,0.85);padding: 40px;}
.speed-search-lib{color:#FFFFFF;font:42px 'Sofia Pro-Bold';text-transform: uppercase;margin-bottom: 25px;}
.speed-select{margin-bottom: 20px;}
.speed-select .nice-select{float:none;height: 50px;background: url('../images/arrow-select.png') no-repeat right center #FFFFFF;border: none;width: 100%;padding: 15px;font:14px 'Sofia Pro';color:#010101;}
.speed-select .nice-select.open{background: url('../images/arrow-select-open.png') no-repeat right center #FFFFFF;width:110%;}
.speed-button{}
.speed-button input{float: right;border: none;background-color: #823DD0;padding: 15px 35px;color: #FFFFFF;font:18px "Sofia Pro-Bold";text-transform: uppercase;
	border-radius: 5px;-o-border-radius: 5px;-webkit-border-radius: 5px;-moz-border-radius: 5px;}
.speed-button input:hover{background-color: #22CAE0;}
.slider-container{position: absolute;top:0;left: 0;width:100%;height: 100%;}
.slider-item{height: 700px;width: 100%;background-repeat: no-repeat;background-position: right;background-size: cover;}
.slider-item img{display: none;}
.slider-container .swiper-pagination{width: 100%;text-align: center;bottom:-40px;left:0;}

.nouv-section{padding:70px 0;}
.nouv-lib{text-transform: uppercase;color:#00A8E2;font:45px "Sofia Pro-Bold";text-align: center;margin-bottom:30px;}
.nouv-product{position: relative;}
.nouv-pagination{padding-top: 15px;width: 100%;text-align: center;}
.nouv-prev{position: absolute;width:32px;height: 61px;background: url('../images/arrow-slider-left.png') no-repeat center center;top:35%;left:-45px;cursor: pointer;}
.nouv-next{position: absolute;width:32px;height: 61px;background: url('../images/arrow-slider-right.png') no-repeat center center;top:35%;right:-45px;cursor: pointer;}
.button-see-all{margin-top: 25px;}
.button-see-all a{display: block;margin: auto;max-width: 250px;background-color: #823DD0;color:#FFFFFF;padding: 15px 10px;width: 100%;text-transform: uppercase;font:15px "Sofia Pro-Bold";text-align: center;
	border-radius: 5px;-o-border-radius: 5px;-webkit-border-radius: 5px;-moz-border-radius: 5px;}
.button-see-all a:hover{background-color: #22CAE0;color: #ffffff;}
.swiper-button-disabled{display: none;}
.nouv-slider .articleBit{margin: 5px auto;max-width: 265px;}
.articleBit{background-color: #FFFFFF;position: relative;padding:20px;max-width: 280px;margin:5px auto 25px;
	box-shadow:1px 1px 6px #CCCCCC;-webkit-box-shadow:1px 1px 6px #CCCCCC;-moz-box-shadow:1px 1px 6px #CCCCCC;-ms-box-shadow:1px 1px 6px #CCCCCC;-o-box-shadow:1px 1px 6px #CCCCCC;}
.articleBitIma{position: relative;}
.etiquette{position: absolute;right:-10px;top:-10px;font:16px 'Sofia Pro';}
.big-img .etiquette{right: 20px;top:20px;font-size: 36px;}
.eti-new{color:#6EC43B;}
.eti-promo{color:#F46036;}
.picto-promo{position: absolute;left:0;top:10;padding: 5px 10px;color:#FFFFFF;font:14px "Sofia Pro-Bold";background-color: #F46036;z-index: 9;}
h3.articleBitLib{margin: 0;line-height: 18px;min-height:50px;}
h3.articleBitLib a{font:15px "Sofia Pro";color:#010101;}
h3.articleBitLib a:hover{color: #22CAE0;}
.articleBitStatus{margin-bottom:10px;}
span.en-stock{color:#6EC43B;font:15px "Sofia Pro";}
span.en-rupture{color:#ED5858;font:15px "Sofia Pro";}
.articleBitPrice{}
span.old-price{color:#4D4D4D;font:14px "Sofia Pro";text-decoration: line-through;display: block;}
strong.promo-price{color:#010101;font:20px "Sofia Pro";display: block;}
.articleBitBtn{position: absolute;bottom:20px;right:20px;}
.add-to-cart-mini{width:40px;height:41px;background: url('../images/btn-cart.png') no-repeat center center;border: none;display: block;}
.add-to-cart-mini:hover{background: url('../images/btn-cart-hover.png') no-repeat center center;border: none;}
.section-phare{background: url('../images/bg-phare.jpg') no-repeat left center #110422;padding: 50px;}
.phare-lib{color:#FFFFFF;text-transform: uppercase;font:70px/80px "Sofia Pro-Bold";margin: 0 0 30px; }
.produitPhare{background-color: #FFFFFF;padding:15px;}
.produitPhare-ima{width: 48%;float: left;position: relative;}
.produitPhare-ima .etiquette{top:10px;right: 10px;}
.produitPhare-details{width: 48%;float: right;padding-top: 15px;}
h3.produitPhare-lib{margin: 0 0 10px;}
h3.produitPhare-lib a{font:22px/26px 'Sofia Pro';color: #000000;}
h3.produitPhare-lib a:hover{color: #22CAE0;}
.produitPhare-desc{font:13px 'Sofia Pro';}
.produitPhare-desc p{font:13px 'Sofia Pro';margin: 0 0 10px;}
.produitPhare-status{margin: 25px 0 10px;}
.produitPhare-price{float: left;}
.produitPhare-price span{display: block;color:#4D4D4D;font:14px "Sofia Pro";text-decoration: line-through;}
.produitPhare-price strong{display: block;color:#010101;font:20px "Sofia Pro";}
.produitPhare-btn{float: right;}
input.phare-add-to-cart{border: none;display: block;background-color: #823DD0;color:#FFFFFF;padding: 15px 20px;text-transform: uppercase;font:12px "Sofia Pro-Bold";text-align: center;
	border-radius: 5px;-o-border-radius: 5px;-webkit-border-radius: 5px;-moz-border-radius: 5px;}
input.phare-add-to-cart:hover{background-color: #22CAE0;}
.section-category{padding: 90px 0 70px;}
.section-category-lib{text-align: center;color:#00A8E2;font:45px "Sofia Pro-Bold";text-align: center;margin-bottom:30px;text-transform: uppercase;}
.section-category-desc{text-align: center;font:13px "Sofia Pro";margin: 0 0 45px;}
.categoryBit{max-width: 355px;margin:0 auto 50px;text-align: center;}
.categoryBit-ima{margin: 0 0 20px;}
h4.categoryBit-lib{margin: 0 0 5px;}
h4.categoryBit-lib a{color:#345AC6;font:18px/21px 'Sofia Pro-Bold';text-transform: uppercase;}
.categoryBit-txt{margin: 0 0 10px;font:13px "Sofia Pro";padding: 0 15px;}
.categoryBit-link a{color:#823DD0;font:13px "Sofia Pro";}
.categoryBit-link a:hover{color:#823DD0;text-decoration: underline!important;}
.section-promotion{padding: 70px 0;background-color: #EAEAEA;}
.section-promotion-lib{text-transform: uppercase;color:#00A8E2;font:45px "Sofia Pro-Bold";text-align: center;margin-bottom:30px;}
.section-promotion .articleBit{margin: 0 auto 30px;}
.content-prefooter{padding: 100px 0 50px;}
.banner-facebook{margin: 0 0 50px;}
.texte-perso-footer{font-size: 12px;}
.icon-assurances{width: 100%;background-color: #2D56B2;padding: 15px 0;}
.iconeBit{}
.iconeBit a{text-transform: uppercase;font:13px/15px "Sofia Pro-Bold";color:#FFFFFF;display: block;min-height: 38px;}
.iconeBit a:hover{color:#22CAE0;}
.icon-1 a{background: url('../images/av-1.png') no-repeat left center;padding: 3px 0 0 40px;}
.icon-2 a{background: url('../images/av-2.png') no-repeat left center;padding: 3px 0 0 32px;}
.icon-3 a{background: url('../images/av-3.png') no-repeat left center;padding: 3px 0 0 50px;}
.icon-4 a{background: url('../images/av-4.png') no-repeat left center;padding: 3px 0 0 42px;}
.icon-5 a{background: url('../images/av-5.png') no-repeat left center;padding: 3px 0 0 35px;}
.icon-6 a{background: url('../images/av-6.png') no-repeat left center;padding: 3px 0 0 28px;}

.footer-wrapper{background-color: #0E1E44;padding: 90px 0 45px;}
.footer-col{}
.footer-col-lib{text-transform: uppercase;font:20px "Sofia Pro-Bold";color: #22CAE0;margin: 0 0 15px;}
ul.footer-link{}
ul.footer-link li{margin: 0 0 15px;}
ul.footer-link li a{color: #ffffff;font-size: 14px;}
ul.footer-link li a:hover{color: #22CAE0;}
.footer-contact{color:#FFFFFF;}
.tel-footer{font-size: 14px;font-family: "Sofia Pro Light";}
.tel-footer span{display: block;}
.tel-footer a{display: block;color:#ffffff;font-size: 22px;line-height: 120%;}
.tel-footer a:hover{color:#22CAE0;}
.tel-footer a p{margin: 0;}
.mail-footer{margin: 15px 0;}
.mail-footer a{display: block;color:#FFFFFF;font-size: 14px;background: url('../images/ico-mail.png') no-repeat left center;padding: 0 0 0 28px;text-decoration: underline;}
.mail-footer a:hover{color:#FFFFFF;}
.adresse-footer{color:#FFFFFF;font-size: 14px;}
.ico-pay{margin: 70px auto 30px;max-width: 580px;}
.logo-footer{text-align: center;margin-bottom: 15px;}
.copyright{text-align: center;font-size: 13px;color:#FFFFFF;}
.copyright a {font-size: 13px;color:#FFFFFF;}
.copyright a:hover{color:#22CAE0;}
.goToTop{position: fixed;right:20px;bottom: 50px;z-index: 999;cursor: pointer;}



/*=============================
			CATEGORIE
===============================*/
ol.breadcrumb.ariane-box {padding: 10px 0;font:15px "Sofia Pro";color: #4d4d4d!important;background-color: transparent;}
ol.breadcrumb.ariane-box *{font:15px "Sofia Pro";color: #4d4d4d!important}
.ariane-box>i{font-size:24px; vertical-align:middle}
.categorie-top{background-color: #EAEAEA;padding: 15px 0 25px;}
h1.categorie-lib{text-align: center;color:#00A8E2;font:45px 'Sofia Pro-Bold';margin: 25px 0 20px;text-transform: uppercase;}
.categorie-description{color:#4D4D4D;margin-bottom: 15px;}
.subCategoryBit{background-color: #ffffff;position: relative;margin-bottom: 20px;
	box-shadow:0 2px 5px #CCCCCC;-webkit-box-shadow:0 2px 5px #CCCCCC;-moz-box-shadow:0 2px 5px #CCCCCC;-ms-box-shadow:0 2px 5px #CCCCCC;-o-box-shadow:0 2px 5px #CCCCCC;}
.subCategoryBit img{position: absolute;left:10px;top:10px;max-width: 100px;}
.subCategoryBit a{text-transform: uppercase;color:#345AC6;font:18px 'Sofia Pro-Bold';padding: 50px 10px 10px 110px;min-height: 120px;display: block;}
.subCategoryBit a:hover{color:#00A8E2;}
.subCategoryBit a i{color:#823DD0;position: absolute;right: 10px;top:50%;font-size: 25px;margin-top: -15px;}
.filtre-cat{width: 100%;background-color: #345AC6;padding: 10px 0;position: relative;z-index: 99;}
	.filter-lib{color:#ffffff;font:16px 'Sofia Pro-Bold';margin-bottom: 10px;}
	.select-f{border:1px solid transparent;background: #ffffff url('../images/arrow-filter.png') no-repeat right center;padding: 15px;color:#4D4D4D;font:15px 'Sofia Pro';text-align: left;width: 100%;}
	.open .select-f{background: #ffffff url('../images/arrow-filter-up.png') no-repeat right center;border:1px solid #00A8E2;border-bottom:none;}
	.filter-item{position: relative;}
		.filtreContenu{border:1px solid #00A8E2;border-top:none;position: absolute;top:100%;left:0;padding: 10px 15px;background-color: #ffffff;width: 100%;display: none;}
			.checkbox-filter label{font-family: "Sofia Pro-Bold";}
	.rangeFilter{margin-bottom: 10px;}
	.rangeFilter label{font:12px 'Sofia Pro';display: block;margin: 0 0 5px;}
	.rangeFilter-champ{position: relative;}
	.rangeFilter-symbol{position: absolute;right: 0;top:0;height: 100%;font:18px "Sofia Pro-Bold";color: #ffffff;padding: 7px 10px 0;background-color: #345AC6;}
	.rangeFilter input{width: 100%;height: 35px;padding: 0 0 0 20px;text-align: left;}
.liste-articles{padding-top: 40px;}

		.sous-categ li{margin:10px 0;    float: left;width: 33.3333%}
		.sous-categ li a{color:#000000; font:15px "Gotham-bold";display:inline-block; transition:all 0.5s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s}
		.sous-categ li a:hover{color:#22CAE0;transition:all 0.3s;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-o-transition:all 0.3s;-ms-transition:all 0.3s}
			.lien-souss-categ li{margin: 12px 0;padding-bottom: 10px;}
			.lien-souss-categ li a{color:#000000; font:14px "Gotham-Medium";display:inline-block;}
			.lien-souss-categ li a:hover{color:#22CAE0;}
	.descr-categ {font-size: 14px;}
	.subMenu .descr-categ-onglet.descr-categ {display: none;}


/*=============================
			ARTICLE
===============================*/
.texte-perso-article{text-align: center;text-transform: uppercase;color:#ffffff;padding: 15px;background-color: #F46036;font:18px 'Sofia Pro-Bold';}
.content-article ol.breadcrumb.ariane-box{margin: 15px 0;}
.retour-article{margin: 0 0 15px;}
	.retour-article a{display: block;float:right;padding:12px 25px;font:12px 'Sofia Pro';border: 1px solid #000000;color:#000;}
	.retour-article a:hover, .retour-article a:focus{background-color: #000;color: #ffffff;}
.mobile-titre{display: none;}
.photo-article{position: relative;margin-top: 20px;}
.content-image-supp {position:relative;z-index:1;margin: 0 ;}
.big-img.has-supp{position:relative;width: 78%;float: right;}
.big-img {width: 100%;}
.big-img-ar{position:relative}
.big-img a {display: block;padding: 15px;background: #fff;}
.supp-img{width: 20%;float: left;}

.article-details{margin-top: 20px;}
	h1.titre-article-art{font:30px 'Sofia Pro';}
	.ref-article{float: left;font:18px 'Sofia Pro-Light';margin-top: 10px;}
	.brand-article{float: right;}
	.description-article{font:14px 'Sofia Pro-Light';}
		.description-article a{color:#000000;text-decoration: underline;}
	.etat-article{font:15px 'Sofia Pro';margin-top: 15px;}
		.rupture-art{color:#22CAE0;}
		.en-stock-art{color:#000000;}
	.plus-que{font:15px 'Sofia Pro';}
		.plus-que span{color:#6EC43B;}
	.prix-article{margin: 20px 0 15px;}
		.article-normal{color: #CDCDCD;text-decoration: line-through;font:18px 'Sofia Pro-Light';}
		.article-promo{color:#000000;font:30px 'Sofia Pro-Light';}
	.action-panier{}
		.quantite-panier{margin-top:10px;float: left;width: 122px;border: 1px solid #999999;height:50px;background: url('../images/arrow-qte.png') no-repeat right center #ffffff;position: relative;}
			.quantite-panier label{font:14px 'Sofia Pro';color:#000000;margin: 0;display: block;padding:16px 10px;}
			.quantite-panier select{display:block;border: none;background: none;font:14px 'Sofia Pro';color:#000000;position: absolute;top:0;left:0;padding:0 0 0 75px;width:100%;height:100%;}
		.button-panier{float: right;}
			.btn-add-to-cart{background-color: #823DD0;width: 310px;text-align: center;color:#ffffff;border: none;text-transform: uppercase;font:22px 'Sofia Pro-Bold';padding: 20px 0;display: block;
				border-radius: 10px;-o-border-radius: 10px;-webkit-border-radius: 10px;-moz-border-radius: 10px;}
			.btn-add-to-cart:hover, .btn-add-to-cart:focus{background-color: #22CAE0;}
	.assurance-article{margin-top: 30px;}
		.assurance-artBit{text-align:center;padding-top:40px;text-transform: uppercase;color:#20359E;font:13px 'Sofia Pro-Bold';background-repeat: no-repeat;background-position: top center;}
		.assurance-artBit-1{background-image: url('../images/ico-call.png');}
		.assurance-artBit-2{background-image: url('../images/ico-lock.png');}
		.assurance-artBit-3{background-image: url('../images/ico-truck.png');}
	.share-article{margin-top: 30px;float: right;}
		.share-article-lib{float: left;padding: 10px 10px 0 0;font:14px 'Sofia Pro-Light';}
		.share-article-btn{float:left;}

.article-bottom-wrapper{}
	.onglet-article-wrapper{background: url('../images/trait-onglet.png') repeat-x bottom;}
		.onglet-article{max-width: 600px;margin: auto;}
			.onglet-article-bit{float: left;width: 33.33%;}
				.onglet-article-bit a{display: block;border-bottom:4px solid #CCCCCC;padding: 20px 0;text-align: center;font:20px 'Sofia Pro';color:#000000;}
				.onglet-article-bit a:hover, .onglet-article-bit a.active{border-bottom:4px solid #823DD0;color:#823DD0;}
	.description-longue{padding: 50px 0 30px;font:14px 'Sofia Pro-Light';}
	.other-products{background-color: #EAEAEA;padding: 70px 0 40px;}
	.pour-completer-lib{text-align: center;color:#20359E;font:30px 'Sofia Pro-Bold';margin-bottom: 25px;text-transform: uppercase;}
	.deja-vu{padding: 50px 0 10px;}
		.deja-vu-lib{text-align: center;color:#20359E;font:30px 'Sofia Pro-Bold';margin-bottom: 25px;text-transform: uppercase;}
		.deja-vu-article{}
		.dejavuBit{background-color: #ffffff;position: relative;padding:10px;margin: 0 auto 15px;max-width: 180px;
			box-shadow:1px 1px 6px #CCCCCC;-webkit-box-shadow:1px 1px 6px #CCCCCC;-moz-box-shadow:1px 1px 6px #CCCCCC;-ms-box-shadow:1px 1px 6px #CCCCCC;-o-box-shadow:1px 1px 6px #CCCCCC;}







.vu-bit a:hover{-webkit-box-shadow: 0px 2px 3px 2px rgba(124,124,124,0.2);-moz-box-shadow: 0px 2px 3px 2px rgba(124,124,124,0.2);box-shadow: 0px 2px 3px 2px rgba(124,124,124,0.2);-o-box-shadow: 0px 2px 3px 2px rgba(124,124,124,0.2);transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;}
	.article-right {float: right;width: 59%;padding:0; color:#000000;max-width: 640px}
		.titre-article-art {margin-bottom: 5px;padding:0;font: 36px "Sofia Pro-Medium";color:#1a1a1a;}


		.bloc-devis-perso {position: relative;margin-bottom: 30px;margin-top: 30px;background: #d6d6d6;}
			.bloc-devis-perso::after{display:none;content:"";position: absolute;left: 50%; transform: translate(-50%,0);top:100%;  width: 0;height: 0;border-style: solid;border-width: 14px 22.5px 0 22.5px;border-color: #d6d6d6 transparent transparent transparent;}
			.bloc-devis-perso>h2 {background: #22CAE0;padding: 18px 15px;font: 20px "Sofia Pro-Medium";position: relative;margin-bottom: 15px;}
				.bloc-devis-perso>h2::after{content:"";position: absolute;left: 50%; transform: translate(-50%,0);-webkit-transform: translate(-50%,0);-ms-transform: translate(-50%,0);-o-transform: translate(-50%,0);top:100%;  width: 0;height: 0;border-style: solid;border-width: 14px 22.5px 0 22.5px;border-color: #22CAE0 transparent transparent transparent;}

				.table-devis {padding:5px 20px 13px 20px;font: 15px "Sofia Pro-Medium";}
					.table-devis th {padding: 15px 2px;font-weight: normal;}
					.table-devis td {background: #fff;border-bottom: 7px solid #d6d6d6;color: #1a1a1a;padding: 14px 2px;height: 70px;}
					.state-stock{font-size: 20px}
					.table-devis label.required::after {display: none;}
				.titre2-art-f{font-size:16px}
				.art-qte ,.quantite-art >.form-group{margin: 5px 0;width: 190px;color:#000000;}
				.art-qte label , .quantite-art >.form-group  label {display: inline-block;font: 14px "Gotham-Book";margin-bottom: 0;vertical-align: middle;width: 75px;}
					.contenu-qte-art {display: inline-block;text-align: left;vertical-align: middle;width: 110px;}
					 .quantite-art >.form-group .form-control{display: inline-block;text-align: center;vertical-align: middle;width: 110px;background-color: #ffffff;border:1px solid #7f7f7f;font:16px "Gotham-Medium"; height: 40px;vertical-align:middle; border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;outline:0!important;box-shadow:none!important;-webkit-box-shadow:none!important; -ms-box-shadow:none!important; -o-box-shadow:none!important; }
					.qte-panier {position: relative;z-index: 1;    width: 100px;margin: auto;}
						.qte-panier img {vertical-align: baseline;}

						.qte-panier a {background-color: #c6c6ce;height:22px;text-align: center;width: 25px;z-index: 1;display: inline-block;vertical-align: middle }

						.inputqte {background-color: #ffffff;border: 1px solid #e6e6e6;font-size: 16px;height: 22px;padding: 0 2px;width: 40px;text-align: center;vertical-align: middle;color: #1a1a1a;}
					strong.prix-devis {font: 16px "Sofia Pro-Medium";color: #22CAE0;}
					.qte-panier a:hover{background-color:#c6c6ce}


		.caract-bit {padding: 10px 0;text-align:left; color:#474949}
			.caract-bit.caract-b a {border: 3px solid #fff;margin: 10px 4px 10px 4px;display:inline-block}

			.caract-bit.caract-b a.selected{border-color:#191919}
			.caract-bit select {background: #f9f9f9 url("../images/arrow_down.png") no-repeat scroll 96% 50%;border: 1px solid #c6c6c6;height: 40px;padding:2px 50px 2px 10px;width: 100%;font-size:13px;color: #474949;}
			.caract-bit .lab-caract{font:15px "Gotham-Medium"; margin-right:5px;color: #000000;}

			.art-descrp-tab {padding:0;box-shadow: 0px -1px 0px 0px rgba(205,205,209,0.9);-webkit-box-shadow: 0px -1px 0px 0px rgba(205,205,209,0.9);-ms-box-shadow:0px -1px 0px 0px rgba(205,205,209,0.9);-o-box-shadow: 0px -1px 0px 0px rgba(205,205,209,0.9);margin-top: 30px; margin-bottom: 35px}
					.titre-tab {font: 18px "sofia Pro";text-align: left;margin-bottom: 0px;padding: 15px 10px;background: #1a1a1a;color: #fff;margin-bottom: 15px;}
 					.tab-content {padding: 0 0 20px 0;font-size:14px}
					 .lim-descr {font-size: 14px;}
		.top-art {display: table;width: 100%;}
			.top-art > * {display: table-cell;width: 50%;vertical-align: bottom;}
			.quantite-art.Quantite{display:none}
			.lien-panier-art {padding: 10px 0;margin-top:10px; }
				.px-article {margin:10px 0 10px 0;}
					.prix-art-panier {font:17px "Gotham-Book";color:#000}


			.etatbit-art {font: 15px "Gotham-Book";margin: 12px 0;color:#000000}
			.table-btn {display: table;width: 100%;}
				.btn-panier-content { position: relative;}
					.btn-panier {display:block; padding:5px 10px;font:20px "Sofia Pro-Medium";border: none;color: #fff;background-color: #22CAE0;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0; text-align:center;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s; cursor:pointer;width:100%; height: 65px; text-transform: uppercase;max-width: 310px; margin-left: auto}
					.btn-panier.btn-add-panier span{background: url("../images/btn_panier.png") no-repeat center left;padding-left: 50px; display: inline-block;line-height: 25px;}
					.content-btn-panier.out-of-stock .btn-panier {line-height: 65px;padding-top: 0;padding-bottom: 0;margin-right: auto;}
					.btn-panier.btn-ctc-panier {background: #1a1a1a;}
					.btn-add-panier:hover{background-color:#D2002A;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;}
		.content-toggle-tab{padding:15px;}
.art-bg .content-livraison-index.pos-relative {height: 140px;margin-top:35px}

.art-bg .content-livraison-index.pos-relative .titre-large{font-size: 15px;color: #fff;}
.art-bg  .absolute-content{padding: 10px 20px}
/*******CSS NEWS DESIGN*************/
.caract-spe-bit>h2 {color: #1a1a1a;font: 20px "Sofia Pro-Medium";}
.caract-spe-bit>h2.has_after{position:relative}
.caract-spe-bit>h2.has_after::after {content: "";width: 40px;height: 40px;background: url("../images/ico_infos.png") no-repeat center;position: absolute;right: 0;bottom: -5px;}
.select-perso-caract {margin-bottom: 10px;}
	.caract-specific .select-perso-caract>label{color: #22CAE0;font: 13px "Gotham-Bold";}
	.value-select-caract select {height: 35px;width: 100%;max-width: 300px;border: 1px solid #E4E4E6;padding: 5px 13px 5px 10px;line-height: normal;background: url("../images/arrow_d_b.png")no-repeat center right 10px;color: #1a1a1a;}
	.table-devis.table-devis-spe {padding: 0;font: 13px "Gotham-Book";color: #22CAE0;border: 1px solid #E4E4E6;background:none; margin-left:0; margin-right:0}
	.bloc-devis-spe.bloc-devis-perso::after{display:none}
	.bloc-devis-spe.bloc-devis-perso {margin-top: 0;margin-bottom: 0;}
	.table-devis.table-devis-spe td{padding:1px 2px; border-bottom: 1px solid #E4E4E6 ; height:45px}
	.table-devis.table-devis-spe tr:last-child td{border-bottom:none}
	.table-devis.table-devis-spe tr.tr-visible:last-child td{border-bottom:none}
	.table-devis-spe thead th {font: 12px "Gotham-Medium";color: #fff;padding: 15px 2px;background: #22CAE0;}

	.table-devis-spe  .sylius-product-variant-price {color: #22CAE0;font: 14px "Gotham-Bold";}
	.devis-perso div#enStock {width: 15px;height: 10px;font-size: 0;background: url("../images/ico_stock.png")no-repeat center;margin: auto;}
	.devis-perso div#horsStock {width: 15px;height: 15px;font-size: 0;background: url("../images/ico_notstock.png")no-repeat center;margin: auto;}
	.qte-panier .form-control {height: 26px;border-radius: 0;text-align: center;max-width: 70px;margin: auto;}
	.table-devis-spe  td span::before {height: 2px;width: 15px;content: "";position: absolute;background: #fff;left: 0;bottom: -2px;display:none}
	.table-devis.table-devis-spe tr.tr-visible:last-child  td span::before {display:none}

/*******END CSS NEWS DESIGN*************/
img.zoomImg {cursor: crosshair;}
/***********ART COMPLEMENTAIRE*********/
.bloc-art-comp {margin-top: 39px;}
.article-bottom {padding: 15px 15px 0 15px ;margin: auto;position: relative;z-index: 1;}
.content-complementaire {position: relative;padding: 10px 0 0 0 ;}
 .article-bottom .swiper-container{padding: 15px 1px;margin-top: 20px; }
.titre-top-content.produit-complement::after{margin-top:0}
.commentaireBit {padding: 10px 0;border-bottom: 1px solid #efefef;}
.commentaireBit  .message-avis{text-align:left;height: auto;}
.art-collection-wrapper {margin-bottom:50px}
.share a {padding: 31px 0 2px 0;margin-top: 0;font: 11px "Gotham-Medium";text-transform: uppercase;    display: inline-block;}
.image-suppp-slide{padding:20px 0}



	@media only screen and (min-width:993px) {
		.table-devis-spe thead th:first-child{padding-left:15px}
	.table-devis-spe tr td:first-child{padding-left:15px}
	}
	@media only screen and (min-width:768px) {
	.btn-art-v.popupBouton {background: #fff;width: 100%;}
	.cc-supp-l {padding-top: 21px;}
	.content-image-supp .swiper-button-disabled {opacity:0;}
	.article-avantage.row-30.avantage-swiper.av-mob .av-box {padding-left: 8px;padding-right: 8px;}
	}

.cons-bloc{width:50%; float:left}

.table-coll::after{content:""; display:block;clear:both}
.inline-btn + br{display:none}
@media only screen and (min-width:1301px) {
	.articlevu-list{padding-left: 10px; padding-right: 10px}
}
/*********Modif ajout panier vignette*******/
.articleBit .etat-article,.articleBit .px-article,.articleBit .art-qte label, .articleBit .quantite-art >.form-group label,.articleBit .caracteristique-art {display: none;}
.articleBit  .art-qte, .articleBit  .quantite-art >.form-group{width:auto}
.articleBit .quantite-art >.form-group .form-control {height: 42px;width: 75px;border: 1px solid #7f7f7f;}
.articleBit .btn-panier {display: block;width: 100%;max-width: 300px;border: none;padding: 11px 5px;background: #1a1a1a;font: 16px "Sofia Pro-Medium";color: #fff;transition:all 0.5s;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s}
	.articleBit .content-out-stock,.articleBit .content-btn-panier.row {    display: table;width: 100%;margin:0}
	.articleBit  .quantite-art.col-auto.align-self-center{padding:0 15px 0 15px!important;display: table-cell;vertical-align: middle;}
	.articleBit  .btn-panier-content.col.align-self-center.pl-0{display: table-cell;vertical-align: middle;padding-right: 15px;}

.articleBit #sylius-product-out-of-stock {display: table-cell;max-width: 80%;margin: auto;color: #22CAE0;font: 16px "Sofia Pro-Medium";padding: 11px 5px;border:none; background:none}
.articleBit  .content-out-stock .content-btn-panier{display: table-cell;vertical-align: middle;padding-right: 15px;}
.articleBit  .btn-panier:hover{background-color: #22CAE0}
.articleBit   .content-out-stock .btn-panier:hover{background-color: #494949}

/*=================================
		PANIER
==================================*/
.panier-deroulant{background-color: #ffffff;position: absolute;right: 0;top:100%;padding: 15px;z-index:9;width: 320px;display: none;}
	.content-panier-deroulant{}
		.deroulant-panierBit{padding: 0 0 10px;border-bottom: 1px solid #cccccc;margin-bottom: 10px;font-size: 13px;}
			.image-panier-der{width: 23%;float: left;}
			.detail-panierBit{width: 75%;float: right;}
				.detail-panierBit h3{margin: 0;font:14px "Sofia Pro";}
				.px-deroulant-panier{font:14px "Sofia Pro-Bold";}
			.btn-panier-der{}
				.btn-panier-der a{display: block;color: #ffffff;padding: 5px 0;font:12px 'Sofia Pro';margin-top: 5px;}
				.btn-panier-der a:hover, .btn-panier-der a:focus{background-color: #22CAE0;color:#ffffff;}
				a.btn-panier-edit{background-color: #000000;}
				a.btn-panier-pay{background-color: #345AC6;}

.table-perso {border-bottom: 1px solid #e6e6e6;}
.media.align-items-center img {max-width: 64px;}
.table-perso .card-header {background: #1a1a1a;color: #fff;text-transform: uppercase;font:14px "Sofia Pro-Medium"}
	.table-perso .card-header  th{font:14px "Sofia Pro-Medium"}
	.table-perso .sylius-quantity {display: block;max-width: 75px;margin: auto;}
	.table-perso span.sylius-quantity input.form-control {background-color: #ffffff;border: 1px solid #7f7f7f;	font: 16px "Gotham-Medium";height: 40px;padding: 0 2px;text-align: center;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;}
table.table-perso td {vertical-align: middle;color: #1a1a1a;}
	.sylius-cart-remove-button{background-color: #D2002A!important;color:#ffffff;padding: 0 5px;border-radius: 5px;}
	.sylius-product-name {font: 16px "Sofia Pro";}
	.table-perso .sylius-total{font:20px "Sofia Pro-Medium";color: #22CAE0;}
	.titre-h2 {font: 20px "Sofia Pro-Medium";}
	.btn-perso-c{background-color: #1a1a1a!important;font: 15px "Sofia Pro-Medium";color: #fff;}
	.btn-font {font: 15px "Sofia Pro-Medium";}
	.btn-perso-c:hover{background-color:#22CAE0!important}
	.btn-big-perso{padding-top: 15px;padding-bottom: 15px;}
	.input-perso-content input.form-control {border: 1px solid #7f7f7f;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;color: #1a1a1a}
	.total-label{font:16px "Sofia Pro-Bold"}
	#sylius-cart-grand-total, .total-cart-summ{font:20px "Sofia Pro-Bold";}
	button.btn-paie{font: 18px "Sofia Pro-Bold";padding:15px 10px;background-color: #345AC6!important;color:#fff; box-shadow: none!important;text-transform:uppercase}
	.btn-paie:hover, .btn-paie:focus{background-color: #22CAE0!important;}
	button#sylius-cart-clear{font: 12px "Sofia Pro";padding: 10px;background-color: #D2002A!important;color:#fff!important}
	.btn-dark-perso:hover, .btn-dark-perso:focus{background-color: #D2002A!important;}
	.btn-link.bg-dark:hover,.btn-link.bg-dark:focus {background-color: #333!important;}
	.content-adress  .steps .steps-icon{color:#fff!important}
	.content-adress  .steps .steps-item.completed {background: #22CAE0;}

	.titre-top-content.bloc-nouv-top{text-align: center;color:#00A8E2;font:38px "Sofia Pro-Bold";text-align: center;margin-bottom:30px;text-transform: uppercase;}
	/*********ETAPE 2********/
	.bg-perso-point {padding:20px 0;}

	.content-adress .steps-item {background: #ccc;color: #fff;}
		.content-adress .steps-item.active {	background: #22CAE0;}
		.content-adress  .steps .steps-item .steps-title {font:17px "Sofia Pro-Medium"}

	.form-perso-content .form-control{border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;font-size: 13px;color:#1a1a1a}
	.form-perso-content .form-control:focus{box-shadow:none;-webkit-box-shadow:none;-ms-box-shadow:none;-o-box-shadow:none;border-color: #1a1a1a;}
	.form-perso-content label.required:after{color: #22CAE0;}

form input:-webkit-autofill {box-shadow: 0 0 0px 1000px #fafafa inset;-webkit-box-shadow: 0 0 0px 1000px #fafafa inset;	-webkit-text-fill-color: #1a1a1a !important;}
form .adresse-newsletter:-webkit-autofill {box-shadow: 0 0 0px 1000px #faf0ee inset;-webkit-box-shadow: 0 0 0px 1000px #faf0ee inset;-webkit-text-fill-color: #1a1a1a !important;
}

.dropdown.address-book-select {border: 1px solid #ced4da;padding:10px 25px 10px 10px;background: #fff url("../images/f_select.png") no-repeat center right 10px;outline: none;}
.dropdown.address-book-select .dropdown-item.active, .dropdown.address-book-select .dropdown-item:active{background:#1a1a1a}
/*input:-internal-autofill-previewed, input:-internal-autofill-selected, textarea:-internal-autofill-previewed, textarea:-internal-autofill-selected, select:-internal-autofill-previewed, select:-internal-autofill-selected{background-color: #fafafa!important;}*/

.body-sylius_shop_checkout_complete .row .col-12.col-lg-8.mb-2.mb-md-4
{flex: 0 0 100%;width: 100%;max-width: 100%;}

.body-sylius_shop_checkout_complete .row.mb-4 .col-12.col-lg-8.mb-2.mb-md-4
{flex: 0 0 66.666667%;width: 100%;max-width:66.666667%;}

.body-sylius_shop_checkout_address button.btn-link.btn,
.body-sylius_shop_checkout_select_shipping button.btn-link.btn,
.body-sylius_shop_checkout_select_payment button.btn-link.btn,
.body-sylius_shop_checkout_complete button.btn-link.btn{
	padding: 15px 50px;background-color: #2D56B2;font-size: 24px;
	}

/** CDE 3 **/
	.nosrayons-navigation >* {display: inline-block;font: 12px "Gotham-book";}

	.max-content>div {max-width: auto;margin-left: auto;margin-right: auto;white-space:nowrap}
	.hasp-m0>p{margin:0}
	.title-payment label.form-check-label {font: 17px "Sofia Pro-Medium";}
.body-sylius_shop_cart_summary	.max-content {margin: 15px auto;	max-width: 400px;}
/*=================================
		FORMULAIRe
==================================*/
.form-perso-content .form-check {display: inline-block;padding-left: 0;}
	.form-perso-content .form-check-input {width: 100%;margin: 0;opacity: 0;top: 0;height: 100%;cursor: pointer;}
	.form-perso-content .form-check-label {background: url("../images/check_off.png") no-repeat left center;padding-left:25px;line-height: 22px;}
		.form-perso-content .form-check-input:checked +  .form-check-label {background-image: url("../images/check_on.png")}
		.contact_form .form-group>label {font-size: 0;height: 0;margin-bottom: 0;line-height: 0;display: block;}
		.contact_form  span.form-error-message {font-size: 12px;position: absolute;bottom: -7px;right: 0;width: 100%;text-align: right;}
		.form-perso-content   .form-group {position: relative;}
		.form-perso-content span.invalid-feedback {position: absolute;right: 0;bottom: -17px;text-align: right;width: 100%;}
/*=================================
			PAGE INSTIT
===================================*/
h1.titre-top-content{text-align: center;font:40px "Sofia Pro-Bold";text-transform: uppercase;color: #00A8E2;margin: 0 0 10px;}
h1.titre-top-content strong{text-align: center;font:40px "Sofia Pro-Bold";text-transform: uppercase;color: #00A8E2;}
h1.titre-page{text-align: center;font:40px "Sofia Pro-Bold";text-transform: uppercase;color: #00A8E2;margin: 0 0 10px;}
.contact-text{text-align: center;margin-bottom: 25px;}
.form-box{position: relative;}
	.form-box i{position: absolute;left:10px;top:50%;margin-top: -12px;}
	.form-box input.form-control{border-radius: 0;padding: .375rem .75rem .375rem 30px;}
	.form-box input.form-control:focus{box-shadow: none;border-color:#ababab;}
	.form-box textarea.form-control{resize: none;border-radius: 0;padding: .375rem .75rem .375rem 30px;}
	.form-box textarea.form-control:focus{box-shadow: none;border-color:#ababab;}
	.form-box-btn button{width: 100%;color: #ffffff;padding: 10px 15px;text-align: center;font:16px "Sofia Pro-Bold";text-transform: uppercase;background-color: #345AC6;border:none;}
	.form-box-btn button:hover, .form-box-btn button:focus{background-color: #00A8E2;color:#ffffff;text-decoration: none;}

.main-content >.ui.breadcrumb {margin: auto;max-width: 1300px;padding: 15px;}
.main-content >.ui.breadcrumb + .ui.segment{margin: auto;max-width: 1300px;}
h1.ui.dividing.header.bitbag-page-name{text-align: center;color:#22CAE0;display: none;font:49px "Sofia Pro-Medium";padding:15px 2%;}
.bitbag-page-content .container {position: relative;}
.centered {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);left:50%}
.bitbag-page-content .h1.centered{text-align: center;color:#22CAE0;font:49px "Sofia Pro-Medium";}
div#accordion .card-header {cursor: pointer;}

/*=================================
			CLIENT
===================================*/
.loginBox{background-color: #f5f5f5;}
.loginBox-lib{margin:0;color:#ffffff;padding:15px 10px;background-color: #000000;text-align: center;text-transform: uppercase;font:18px "Sofia Pro-Bold";}
.loginBox-content{padding: 20px;}
.loginBox-btn{float: left;width: 49%;}
	.loginBox-btn button{display: block;padding: 15px 0;color: #ffffff;border: none;background-color: #20359E;text-transform: uppercase;font:14px "Sofia Pro";width: 100%;text-align: center;}
	.loginBox-btn button:hover, .loginBox-btn button:focus{background-color: #000000;}
.loginBox-link{float: right;width: 49%;}
	.loginBox-link a{display: block;padding: 15px 0;color: #ffffff;border: none;background-color: #000000;text-transform: uppercase;font:14px "Sofia Pro";width: 100%;text-align: center;}
	.loginBox-link a:hover, .loginBox-link a:focus{background-color: #cccccc;color: #000000;}
.loginBox-createBtn{}
	.loginBox-createBtn a{display: block;padding: 15px 0;color: #ffffff;border: none;background-color: #20359E;text-transform: uppercase;font:14px "Sofia Pro";width: 100%;text-align: center;}
	.loginBox-createBtn a:hover, .loginBox-createBtn a:focus{background-color: #000000;color: #ffffff;}
.loginBox-btn2{padding: 0 25px 25px;}
	.loginBox-btn2 button{display: block;padding: 15px 0;color: #ffffff;border: none;background-color: #20359E;text-transform: uppercase;font:14px "Sofia Pro";width: 100%;text-align: center;}
	.loginBox-btn2 button:hover, .loginBox-btn button:focus{background-color: #000000;}
.form-group .form-control{border-radius: 0;}
.form-group .form-control:focus{box-shadow: none;border-color: #000000;}
.register-have-account{text-align: center;margin: 10px 0 15px;}
.register-have-account a{text-decoration: underline;color: #20359E;}

.titre-compte-client {position: relative;}
.titre-compte-client .titre-top-content{background-color: #1a1a1a;margin-bottom: 20px;}
.titre-compte-client 	.titre-top-content > strong {color: #fff;font:20px "Sofia Pro-Medium";padding:12px 10px;background: none;}
.titre-compte-client 	.titre-top-content::after{display: none;}
.icone-inline {display:inline-block;width: 60px;text-align: center;background: #22CAE0;color: #fff;text-transform: uppercase;height: 60px;vertical-align: middle;font-size: 0;}
.icone-inline span {font-size: 0;display: inline-block;line-height: 0;text-align: center;letter-spacing: 0;width: 20px;}
.icone-inline span:first-letter {font: 30px "Sofia Pro-Medium";line-height: 60px;}
.nom-user {display: inline-block;padding-left: 10px;width: 67%;vertical-align:middle}
.nom-user strong {font: 25px "Sofia Pro-Medium";display: block;padding-top: 6px;word-wrap: break-word;line-height: 1.5rem;text-transform: capitalize;}
.image-bienvenu img {}
.image-bienvenu h1 {position: absolute;top: 40%;left: 0;margin: 0;width: 100%;color: #ffffff;text-align: center;background-color: rgba(0, 0, 0, 0.7);font:40px 'Sofia Pro-Bold';text-transform: uppercase;padding: 15px 10px;}
	.image-bienvenu {position: relative;height: 100%;display: table;width: 100%;}
	.object-fit-img img{height: 100%; object-fit: cover;-ms-object-fit: cover; width: 100%}
	.list-item-bit {background: #fff;}
		.list-item-bit a {font-size:15px;color: #1a1a1a;padding: 16px 20px 16px 60px;line-height: 2.4rem;position: relative;	}
		.lien-home  {background-image: url("../images/ico_paie.svg");background-repeat: no-repeat;background-position: 18px 50%;}
		.lien-user {background-image: url("../images/ico_info.svg");background-repeat: no-repeat;background-position: 18px 50%;}
		.lien-lock {background-image: url("../images/ico_mdp.svg");background-repeat: no-repeat;background-position: 20px 50%;}
		.lien-book {background-image: url("../images/ico_adresse.svg");background-repeat: no-repeat;background-position: 20px 50%;}
		.lien-cart {background-image: url("../images/cmd.svg");background-repeat: no-repeat;background-position: 20px 50%;}
		.lien-loggout {background-image:url('../images/ico_deconnex.svg');background-repeat: no-repeat;background-position: 20px 50%;color: #D10000!important}
		.list-item-bit a::after {content: "";bottom: 0;left: 60px;width: calc(100% - 75px);border-top: 1px solid #ced4da;position: absolute;}
		.list-item-bit:last-child  a::after{display: none;}
		.sylius_shop_account_dashboard a.lien-home:before, .sylius_shop_account_profile_update a.lien-user:before,.sylius_shop_account_change_password a.lien-lock:before,.sylius_shop_account_address_book_index a.lien-book:before,.sylius_shop_account_address_book_create a.lien-book:before ,.sylius_shop_account_address_book_update a.lien-book:before, .sylius_shop_account_order_index a.lien-cart::before {border-right: 4px solid #22CAE0;bottom: 0;content: "";left: -3px;position: absolute;top: 0;}
		.sylius_shop_account_dashboard .lien-home,.sylius_shop_account_profile_update a.lien-user,.sylius_shop_account_change_password a.lien-lock,.sylius_shop_account_address_book_index a.lien-book ,.sylius_shop_account_address_book_create a.lien-book,.sylius_shop_account_address_book_update  a.lien-book, .sylius_shop_account_order_index a.lien-cart {background-color: rgba(242,242,242,0.7);font-weight: bold;}
		.form-perso-content select.form-control{border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;background: #fff url("../images/f_select.png") no-repeat center right 10px;}
		section.big-wrapper>.bloc-show-administrable {margin-top: 0;}
		.content-adress.bg-perso-point.pt-0.pt-md-3.pb-3.pb-md-5{    background: rgba(242,242,242,0.7);}

		/*******ADRESSE*****/
		.list-adress .card.rounded-0.border-0 {border-right: 1px solid #ccc!important;}
		.list-adress .col-lg-6:nth-child(2n) .card.rounded-0.border-0, .list-adress .col-lg-6:last-child .card.rounded-0.border-0 {border: none!important;}
		.sylius_shop_account_dashboard + .col-12 .bg-white.menu-right.p-lg-4 {padding: 0!important;}
		a.mr-1.btn.btn-link.btn-br.rounded-0.border-0.text-uppercase.text-white {line-height: 36px;}
		.list-adress .btn.btn-icone {width: 45px;min-width: 45px;margin-left:5px;line-height: 21px;}
		.bg-red{background-color:#22CAE0!important;}
	.retour-cl{width: 30px;height: 30px;margin-top: -1.5rem;background: url("../images/retours.svg")no-repeat center;position: absolute;left:5px;    top: 50%;transform: translate(0, -50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);}
	.list-adress address {min-height: 142px;}
	/**********HISTORIQUE COMMANDE*********/
	table#sylius-checkout-subtotal {color: #1a1a1a;}
    .content-bottom-order .table-perso .card-header{background-color: #1a1a1a!important;font-size: 15px;}
    .content-bottom-order .table-perso .card-header th{font-size: 15px;padding-left: 8px;padding-right: 8px;white-space: pre;}
    .content-bottom-order .table-perso .card-header td{padding-left: 8px;padding-right: 8px;}
      .content-bottom-order .table-perso .sylius-product-name{font-size: 16px;}
      .content-bottom-order .table-perso table {font-size: 16px;}
      .content-bottom-order .table-perso  .total-cart-summ{font-size: 18px;}
       .content-bottom-order .table-perso .total-label{font-size: 15px;}
     .content-bottom-order #order-invoices  h3#shipping-state{background-color: #22CAE0;margin-bottom: 20px;font:20px "Sofia Pro-Medium";text-transform: uppercase; padding: 12px 10px;color:#fff; text-align: center;margin-left:-1rem;margin-right:-1rem;}
     .content-bottom-order #order-invoices table th {font: 15px "Sofia Pro-Medium";text-transform: uppercase;background-color: #1a1a1a;color:#fff}
     .content-bottom-order #order-invoices table{font-size: 14px; color:#1a1a1a;}
     #order-invoices table a {color: #22CAE0;}
     #order-invoices table a:hover{text-decoration: underline!important;}
        #order-invoices table a i.icon.download {width: 30px;display: inline-block;height: 23px;background: url("../images/ico_download.png") no-repeat center;vertical-align: middle;}
		.table-histo-account {font-size: 14px;}
		table#sylius-checkout-subtotal td {font-size: 13px;}
.franklin-medium{font-family : "Sofia Pro-Medium"}
@media only screen and (min-device-width : 1300px) and (max-device-width : 1500px){
	.container{max-width:90%!important}
	.bloc-slider .container.p-0 {max-width: 100%!important; }
	.max_1200 {max-width: 90%;}
	.max-tri_c {max-width: 90%;}
	div#contenuArticle .container {max-width: 1300px!important;}
	.bloc_administrable_i .container {max-width: 100%!important;}
	.content_commun_logging .container {max-width: 100%!important;}
	.text-opti .container {max-width: 100%!important;}
	.bloc_tendance  >.container{max-width:1100px}
	.newsletter_wrapper .container.p-0 {max-width: 1300px!important;}
	.contenuArticle .container.p-0 {max-width: 1300px!important;}
	.content-article-b{ max-width: 70%;}
}
@media only screen and (min-device-width : 1100px){
	.image_l_admin {width: 700px;	}
}
h2.ui.center.aligned.icon.header {text-align: center;}
.sortable a:hover {color: rgba(255,255,255,0.5);}
body:not(.modal-open) .modal-backdrop.show {opacity: 0!important;display: none!important;}

/************COOKIES*********/
#modalCookies2 .ch-cookie-consent__category label{border-radius: 0!important;-webkit-border-radius: 0!important;-ms-border-radius: 0!important;margin-bottom: 0;}
#modalCookies2 .ch-cookie-consent__category-toggle input:checked+label,#modalCookies .ch-cookie-consent__category-toggle input+label:hover{background-color: #22CAE0!important;}
#modalCookies2 .ch-cookie-consent h4 {font-size: 12px;}
#modalCookies2 .ch-cookie-consent__category{padding: 10px 0;align-items: center;-webkit-align-items: center;}
.ch-cookie-consent__category-group {margin-left: auto; margin-right: auto;max-width: 600px;}
#modalCookies2 a.ch-cookie-consent__read-more,.ch-cookie-consent__toggle-details span {text-decoration: underline;color: #fff ;margin-left:5px;font-weight: bold;cursor: pointer;}
.ch-cookie-consent__toggle-details span:hover{text-decoration: none;}
h3.ch-cookie-consent__title {color: #fff;text-align: center;font-size: 14px;}
#modalCookies2 {position: fixed;bottom: 0;width: 100%;padding:15px;background:#22CAE0;z-index: 9999;color: #fff;text-align: center;display: none;}
.ch-cookie-consent__toggle-details-hide {display: none;}
.ch-cookie-consent__category-group {display: none;    position: fixed;width: 100%;max-width: 400px;transform: translate(-50%, -50%);padding: 15px;background: #fff;z-index: 99;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);z-index: 99;}
button#cookie_consent_use_all_cookies{    justify-content: center;-ms-justify-content: center;margin-top: 0;}
button.close-me {position: absolute;right: -14px;border: none;background:#000;border-radius: 100%;font-size: 20px;font-weight: bold;color: #fff;padding: 0;width: 30px;height: 30px;line-height: 5px;top: -11px;text-align: center;}
button#cookie_consent_use_only_functional_cookies {background: none;color: #333!important;min-width: auto;padding: 0;text-decoration: underline;margin-right: 10px; border: none;margin-top: 0; justify-content: center;-ms-justify-content: center;}
.ch-cookie-consent{background: none!important;}
body .loading-overlay {position: fixed;background: rgba(0, 0, 0, 0.1);}  
.ch-cookie-consent .btn{background: #22CAE0 ;color:#fff!important; text-align:center;  width: auto;margin-top:0; border-radius: 0; -webkit-border-radius: 0; -ms-border-radius: 0;padding: 5px 20px }
.loading-overlay-c {position: fixed;top: 0;left: 0;z-index: 9;display: none;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);}
.ch-cookie-consent__category-toggle input:checked+label, .ch-cookie-consent__category-toggle input+label:hover{background-color: #1a1a1a!important;}
.ch-cookie-consent__category:first-child {border-top: none;}
.close-cookies{background:#fff url("../images/close_popup.png") no-repeat center;font-size: 0;padding: 15px; position: absolute;right:0 ; top:0}
 
