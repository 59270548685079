@font-face {
	font-family:'Gotham-Book';
	src: url('../fonts/Gotham-Book.woff2') format('woff2'),
		url('../fonts/Gotham-Book_gdi.woff') format('woff'); 
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-display: swap;
}

@font-face {
	font-family:'Gotham-Bold';
	src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
		 url('../fonts/Gotham-Bold.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-display: swap;
}

@font-face {
	font-family:'Gotham-Medium';
	src:url('../fonts/Gotham-Medium.woff2') format('woff2'),
		url('../fonts/Gotham-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-display: swap;
}
@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro.woff2') format('woff2'),
        url('../fonts/SofiaPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro-Medium';
    src: url('../fonts/SofiaPro-Medium.woff2') format('woff2'),
        url('../fonts/SofiaPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia Pro-Light';
    src: url('../fonts/SofiaPro-Light.woff2') format('woff2'),
        url('../fonts/SofiaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia Pro-Light-Italic';
    src: url('../fonts/SofiaPro-LightItalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia Pro-Bold';
    src: url('../fonts/SofiaPro-Bold.woff2') format('woff2'),
        url('../fonts/SofiaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}





